const firebaseConfig = {
  projectId: "blackbrickgardens",
  appId: "1:197205644914:web:db7a2611364d0e620dd4eb",
  databaseURL:
    "https://blackbrickgardens-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "blackbrickgardens.appspot.com",
  locationId: "us-central",
  apiKey: "AIzaSyCyA-bTls815Ul-bZmj23pGrTwGKvkBgWc",
  authDomain: "blackbrickgardens.firebaseapp.com",
  messagingSenderId: "197205644914",
  measurementId: "G-L709648X7N",
};

export default firebaseConfig;
